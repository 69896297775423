// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
//require("trix")
//require("@rails/actiontext")
require("local-time").start()
require("@rails/ujs").start()

import './channels/**/*_channel.js'
import "./controllers"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap
import LocalTime from "local-time"

document.addEventListener("turbo:load", () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  });

  if (document.body.classList.contains('listings-index')) {
    // Cache DOM elements
    const downloadNotice = $('#download-notice');
    const globalDownloadSpinner = $('#global-download-spinner');
    const downloadAllDropdownMenu = $('#download-all-dropdown-menu');
    const downloadSelectedDropdownMenu = $('#download-selected-dropdown-menu');
    const deleteButton = $('#deleteSelectedListingButton');

    let isDownloading = false;

    // Function to handle download actions
    function handleDownload(exportTarget, useDashes, type, listingIds = []) {
      if (isDownloading) {
        alert("A download is already in progress. Please wait for the current download to finish.");
        return;
      }

      // Confirmation based on type
      let confirmMessage = "Please ensure the lot numbers are not duplicated, as image filenames will be based on lot number and if there are duplicate lot numbers in your listing zip export/download, images for that listing may be overwritten.\n\nClick OK to continue with your download.";
      if (type === 'downloadAll') {
        confirmMessage = "Please ensure the lot numbers are not duplicated, as image filenames will be based on lot number and if there are duplicate lot numbers in your listing zip export/download, images for that listing may be overwritten.\n\nClick OK to continue with your download.";
      }

      if (!confirm(confirmMessage)) {
        return;
      }

      // If downloading selected, ensure listings are selected
      if (type === 'downloadSelected' && listingIds.length === 0) {
        alert('Please select at least one listing to download.');
        return;
      }

      isDownloading = true;
      displayDownloadNotice();
      globalDownloadSpinner.removeClass('visually-hidden');

      // Prepare payload
      const payload = {
        export_target: exportTarget,
        use_dashes: useDashes,
        listing_ids: listingIds
      };

      fetch(`/listings/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        body: JSON.stringify(payload)
      })
      .then(response => response.json())
      .then(data => {
        if (data.id) {
          pollForProgress(data.id);
        } else {
          throw new Error('Invalid response from server.');
        }
      })
      .catch(error => {
        console.error('Error initiating download:', error);
        alert('An error occurred while starting the download. Please try again.');
        resetDownloadState();
      });
    }

    // Function to poll for download progress
    function pollForProgress(taskId) {
      const poll = () => {
        fetch(`/listings/listing_zip_status/${taskId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          }
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === 'complete') {
            window.location.href = data.download_url;
            resetDownloadState();
          } else if (data.status === 'failed') {
            alert('Failed to generate the download. Please try again.');
            resetDownloadState();
          } else {
            setTimeout(poll, 5000); // Poll every 5 seconds
          }
        })
        .catch(error => {
          console.error('Error polling download status:', error);
          alert('An error occurred while checking the download status.');
          resetDownloadState();
        });
      };

      poll();
    }

    // Function to display download notice
    function displayDownloadNotice() {
      downloadNotice.removeClass('visually-hidden').text("Generating listing zip for download! Please wait...");
    }

    // Function to reset download state
    function resetDownloadState() {
      isDownloading = false;
      globalDownloadSpinner.addClass('visually-hidden');
      downloadNotice.addClass('visually-hidden').text("Generating listing zip for download! Please wait...");
    }

    // Handle Download All button clicks
    downloadAllDropdownMenu.on('click', '.download-option', function(event) {
      event.preventDefault();
      const button = $(this);
      const exportTarget = button.data('export-target');
      const useDashes = button.data('use-dashes') === true || button.data('use-dashes') === 'true';
      const type = button.data('type');

      // Show spinner for this button
      const spinnerId = `#spinner-${type}-${exportTarget}-${useDashes ? 'dashes' : 'underscores'}`;
      const spinner = $(spinnerId);
      spinner.removeClass('visually-hidden');

      handleDownload(exportTarget, useDashes, type)
        .finally(() => {
          // Hide spinner after handling
          spinner.addClass('visually-hidden');
        });
    });

    // Handle Download Selected button clicks
    downloadSelectedDropdownMenu.on('click', '.download-option', function(event) {
      event.preventDefault();
      const button = $(this);
      const exportTarget = button.data('export-target');
      const useDashes = button.data('use-dashes') === true || button.data('use-dashes') === 'true';
      const type = button.data('type');

      // Get selected listing IDs
      const listingIds = selectedIds.slice(); // Clone the array to avoid mutation

      // Show spinner for this button
      const spinnerId = `#spinner-${type}-${exportTarget}-${useDashes ? 'dashes' : 'underscores'}`;
      const spinner = $(spinnerId);
      spinner.removeClass('visually-hidden');

      handleDownload(exportTarget, useDashes, type, listingIds)
        .finally(() => {
          // Hide spinner after handling
          spinner.addClass('visually-hidden');
        });
    });

    // Function to update UI based on selected listings
    let selectedIds = []; // Array to store selected listing IDs

    function updateSelectedButtons() {
      const downloadSelectedButton = $('#download-selected-dropdown-btn');
      const downloadSelectedCount = selectedIds.length;

      if (downloadSelectedCount > 0) {
        downloadSelectedButton.html(`<i class="bi bi-arrow-down-circle-fill"></i> &nbsp Download ${downloadSelectedCount} selected listings &nbsp`);
      } else {
        downloadSelectedButton.html(`<i class="bi bi-arrow-down-circle-fill"></i> &nbsp Download Selected Listings &nbsp`);
      }

      // Update delete button text
      if (downloadSelectedCount > 0) {
        deleteButton.text(`Remove ${downloadSelectedCount} selected listings`);
      } else {
        deleteButton.text('Remove Selected Listings');
      }
    }

    function removeRowsFromDataTable(ids) {
      ids.forEach(id => {
        const listing = $(`#listing-${id}`);
        if (listing.length) listing.remove();
      });
    }

    // Initialize DataTable
    if (!$.fn.DataTable.isDataTable('#listingDataTable')) {
      let table = new DataTable('#listingDataTable', {
        order: [[0, 'desc']],
        pageLength: 100,
        responsive: true,
        search: {
          smart: true, // Enable smart search across all columns
          caseInsensitive: true // Make search case-insensitive
        },
        columnDefs: [
          { searchable: true, targets: '_all' },  // Ensure all columns are searchable
          { targets: 0, width: '30px', responsivePriority: 1 }, // ID column
          { targets: 1, width: '30px', responsivePriority: 2}, // Lot Number column
          { targets: 2, responsivePriority: 3 }, // Photos column
          { targets: 3, responsivePriority: 4 }, // Title column
          { targets: '_all', responsivePriority: 5 } // Other columns
        ]
      });

      // Handle row selection
      table.on('click', 'tbody tr', function (e) {
        const listingId = parseInt(table.row(this).data()[0], 10); // Assuming ID is in the first column
        const index = selectedIds.indexOf(listingId);

        if (index === -1) {
          selectedIds.push(listingId);
          $(this).addClass('selected');
        } else {
          selectedIds.splice(index, 1);
          $(this).removeClass('selected');
        }

        updateSelectedButtons();
      });

      // Handle Delete Selected Listings
      deleteButton.off('click').on('click', function () {
        if (selectedIds.length > 0) {
          if (confirm("Are you sure you want to delete these items?")) {
            let url = '/listings/batch_destroy';
            fetch(url, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
              },
              body: JSON.stringify({ listing_ids: selectedIds })
            })
            .then(response => {
              if (response.ok) {
                removeRowsFromDataTable(selectedIds);
                selectedIds = [];
                updateSelectedButtons();
                // Optionally, display a success message
                window.location.reload();
              } else {
                alert('Failed to delete items. Please try again or contact the admin if the issue persists.');
              }
            })
            .catch(error => {
              console.error('Error deleting items:', error);
              alert('Failed to delete items. Please try again.');
            });
          }
        } else {
          alert('Please select at least one listing to delete.');
        }
      });
    }

    console.log('Listings index page JS loaded!');
  }
});

LocalTime.start()

console.log("application.js is loaded");
